<script lang="ts">
	import '../styles/fonts.scss';
	import '../app.scss';

	import SiteHeader from '$components/SiteHeader.svelte';
	import SiteFooter from '$components/SiteFooter.svelte';
	import NavigationProgressBar from '$components/NavigationProgressBar.svelte';

	import SanityPreviewVisualEditing from '$components/SanityPreviewVisualEditing.svelte';
	import { previewModeActivated } from '$utils/sanityUtils';
</script>

{#if previewModeActivated}
	<!-- Sanity CMS visual editing config -->
	<SanityPreviewVisualEditing />
{/if}

<svelte:head>
	<link
		rel="preload"
		href="/fonts/ABCFavoritVariable/ABCFavoritVariableEdu.woff2"
		as="font"
		type="font/woff2"
		crossorigin="anonymous"
	/>
</svelte:head>

<NavigationProgressBar />

<SiteHeader />

<slot />

<SiteFooter />

<style>
	:global(:root) {
		--background-color: var(--main-bg-color);
		--main-text-color: var(--main-body-grey);
		--header-link-hover: rgb(var(--blue));
		--header-bottom-line-color: rgb(var(--main-body-grey));
	}

	:global(body) {
		background-color: rgb(var(--background-color));
		color: rgb(var(--main-text-color));
	}
</style>
